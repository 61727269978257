import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import parseFlexibleContent from '../utils/parse-flexible-content';
import renderFlexibleContent from '../utils/render-flexible-content';

import PageHeader from '../components/molecules/page-header';
import Intro from '../components/molecules/intro';
import AdditionalContent from '../components/molecules/additional-content';

const modules = {
  Intro,
  ContentBlock: AdditionalContent,
};

const Page = ({ data }) => {
  const flexibleContent = parseFlexibleContent(data.page.acf.flexible_content_page);

  return (
    <Layout>
      <PageHeader title={data.page.title} />
      {renderFlexibleContent(flexibleContent, modules)}
    </Layout>
  );
};

export const query = graphql`
  query($id: Int!) {
    page: wordpressPage(wordpress_id: { eq: $id }) {
      yoast {
        title
        metadesc
        ogImage: opengraph_image {
          localFile {
            childImageSharp {
              resize(width: 1200, height: 630) {
                src
              }
            }
          }
        }
        twitterImage: twitter_image {
          localFile {
            childImageSharp {
              resize(width: 1000, height: 1000) {
                src
              }
            }
          }
        }
      }
      title
      acf {
        flexible_content_page {
          module: __typename
          ... on WordPressAcf_intro {
            title
            body
          }
          ... on WordPressAcf_content_block {
            body
          }
        }
      }
    }
  }
`;

export default Page;
